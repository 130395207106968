function setSidebarCollapsed(collapsed) {
  return {
    type: 'SET_SIDEBAR_COLLAPSED',
    collapsed
  };
};

function setSidebarHidden(hidden) {
  return {
    type: 'SET_SIDEBAR_HIDDEN',
    hidden
  };
};

export { setSidebarCollapsed, setSidebarHidden };
