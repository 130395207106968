import React from 'react';
import styled from 'styled-components';
import Headline from '../components/Headline';
import Category from '../components/Category';
import Showcase from '../components/Showcase';
import iphoneScreenshotInvyte from '../images/invyte-ticket-purchase.svg';
import crewsScreenshot from '../images/invyte-crews.png';
import ticketScreenshot from '../images/invyte-ticket.png';
import vaultScreenshot from '../images/invyte-ticket-vault.png';
import infrastructureImg from '../images/infrastructure.svg';
import clientSideImg from '../images/client-side.svg';
import backendImg from '../images/backend.svg';
import media from '../util/media';

const IPhoneImage = styled.img`
  height: 100%;
`

const Highlight = styled.span`
  color: #38d39f;
`;

const MobileScreenshots = styled.div`
  height: auto;
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 50px;
  justify-items: center;

  ${
    media.phone`
      grid-template-columns: 1fr;
    `
  }
`;

const MobileScreenshot = styled.img`
  width: 100%;

  ${
    media.phone`
      width: 80%;
      display: ${props => props.main ? 'block' : 'none'};
    `
  }
`;

export default function InvyteShowcase() {
  return (
    <Showcase
      title="Invyte"
      subtitle="Event ticket sharing"
      platforms="iOS and Android"
      deliverables="Apps, back-end and infrastructure"
      url="https://invyte.app"
      coverContent={
        <IPhoneImage src={iphoneScreenshotInvyte} />
      }>
      <Headline title="My role">
          I'm the co-founder of the startup behind the product. My primary roles are full-stack developer and system architect.
      </Headline>
      <Category title="Client-side technologies" img={clientSideImg}>
        I've worked on both the iOS and the Android app which are built natively using <Highlight>Swift</Highlight> and <Highlight>Kotlin</Highlight>. Both are complex applications based on an MVVM architecture.
      </Category>
      <MobileScreenshots>
        <MobileScreenshot src={crewsScreenshot} />
        <MobileScreenshot src={ticketScreenshot} />
        <MobileScreenshot main src={vaultScreenshot} />
      </MobileScreenshots>
      <Category reversed title="Back-end technologies" img={backendImg}>
        Invyte is backed by a REST API built using <Highlight>Python</Highlight> and Pyramid interacting with a Postgres database. During the development I've also used technologies like Celery, Redis, Stripe and ElasticSearch among others.
      </Category>
      <Category title="Devops and infrastructure" img={infrastructureImg}>
        I've deployed Invyte on a microservice infrastructure using <Highlight>Kubernetes</Highlight>. Various Google Cloud Platform services are used such as Storage and Stackdriver. I also heavily use <Highlight>Docker</Highlight> for development.
      </Category>
    </Showcase>
  );
}
