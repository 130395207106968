import React from 'react';
import styled from 'styled-components';
import Showcase from '../components/Showcase';
import Headline from '../components/Headline';
import Category from '../components/Category';
import relationsImg from '../images/ffwd/relations.png';
import documentsImg from '../images/ffwd/documents.png';
import emailsImg from '../images/ffwd/emails.png';
import frontendImg from '../images/frontend.svg';
import media from '../util/media';

const AndroidImage = styled.img`
  height: 100%;
`

const Content = styled.div`
  margin: 0 auto;
  max-width: 1750px;
`;


const Highlight = styled.span`
  color: #59ADFF;
`;

const MobileScreenshots = styled.div`
  height: auto;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 150px;
  justify-content: center;

  ${
    media.phone`
      grid-template-columns: 1fr;
    `
  }
`;

const MobileScreenshot = styled.img`
  width: 100%;

  ${
    media.phone`
      width: 80%;
      display: ${props => props.main ? 'block' : 'none'};
    `
  }
`;

export default function FasterForwardOnRouteShowcase() {
  return (
    <Showcase
      title="Faster Forward On-Route"
      subtitle="The app for business consultants on the go."
      platforms="Android"
      deliverables="Mobile app"
      url="https://www.fasterforward.nl/"
      coverContent={
        <AndroidImage src={relationsImg} />
      }>
      <Headline title="My role">
        We worked on this project in a team of two developers. I focused on application archicture on top of regular development activities.
      </Headline>
      <MobileScreenshots>
        <MobileScreenshot src={emailsImg} />
        <MobileScreenshot main src={documentsImg} />
      </MobileScreenshots>
      <Category reversed title="Technologies" img={frontendImg}>
        Faster Forward On-Route was built in <Highlight>Kotlin</Highlight> using an MVVM architecture. It's backed by a REST API built by the client.
      </Category>
    </Showcase>
  );
}
