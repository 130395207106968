import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from '../util/media';

const propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  img: PropTypes.node,
  reversed: PropTypes.bool
};

const defaultProps = {
  img: null,
  reversed: false
};

const StyledCategory = styled.div`
  margin: 300px 150px;
  display: flex;
  flex-direction: ${props => props.reversed ? 'row-reverse' : 'row'};
  justify-content: space-around;

  ${
    media.tablet`
      margin: 300px 50px;
    `
  }

  ${
    media.phone`
      margin: 100px 50px;
      flex-direction: column;
    `
  }
`;

const CategoryImage = styled.img`
  width: 300px;
  height: 300px;
  max-width: 80%;
`;

const Description = styled.div`
  width: 45%;

  ${
    media.phone`
      width: 100%;
    `
  }
`;

function Category(props) {
  return (
    <StyledCategory reversed={props.reversed}>
      <CategoryImage src={props.img} />
      <Description>
        <h2>{props.title}</h2>
        <p>{props.children}</p>
      </Description>
    </StyledCategory>
  );
}

Category.propTypes = propTypes;
Category.defaultProps = defaultProps;

export default Category;
