const initialState = {
  collapsed: false,
  hidden: false
};

export default function sidebarReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_SIDEBAR_COLLAPSED':
      return Object.assign({}, state, {
        collapsed: action.collapsed
      });
    case 'SET_SIDEBAR_HIDDEN':
      return Object.assign({}, state, {
        hidden: action.hidden
      });
    default:
      return state;
  }
}
