import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const propTypes = {
  role: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired
}

const ExperienceContainer = styled.div`
  margin-bottom: 50px;

  h3 {
    line-height: 1;
  }

  p {
    line-height: 1;
    margin: 10px 0;
  }
`;

function Experience(props) {
  return (
    <ExperienceContainer>
      <h3>{props.role}</h3>
      <p>{props.company}</p>
      <p><i>{props.time}</i></p>
    </ExperienceContainer>
  );
}

Experience.propTypes = propTypes;

export default Experience;
