import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import HorizontalPager from '../components/HorizontalPager';
import { setSidebarHidden } from '../actions/sidebar';
import CoderShowcase from '../showcases/Coder';
import InvyteShowcase from '../showcases/Invyte';
import FasterForwardOnRouteShowcase from '../showcases/FasterForwardOnRoute';
import media from '../util/media';

const BackArrow = styled(Link)`
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 2;
  font-size: 25px;
  text-decoration: none;

  ${
    media.phone`
      font-size: 30px;
    `
  }
`;

function Showcase(props) {
  props.dispatch(setSidebarHidden(true));
  return (
    <div>
      <BackArrow to="/" className="fas fa-arrow-left" />
      <HorizontalPager>
        <InvyteShowcase />
        <CoderShowcase />
        <FasterForwardOnRouteShowcase />
      </HorizontalPager>
    </div>
  );
}

export default connect()(Showcase);
