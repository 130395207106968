import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { setSidebarCollapsed } from '../actions/sidebar';
import media from '../util/media';

const propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
  collapsed: PropTypes.bool,
  hidden: PropTypes.bool
};

const defaultProps = {
  children: null,
  header: null,
  footer: null,
  collapsed: false,
  hidden: false
};

const StyledExpandButton = styled.i`
  position: fixed;
  z-index: 3;
  margin: 25px;
  font-size: 20px;
  display: ${props => props.sidebarHidden || !props.sidebarCollapsed ? 'none' : 'block'};

  ${
    media.phone`
      font-size: 30px;
    `
  }
`;

const OuterContainer = styled.div`
  border-radius: 0px;
  height: 100%;
  width: 100%;
  display: ${props => props.hidden || props.collapsed ? 'none' : 'block'};

  > * {
    padding: 0px 20px;
  }
`;

const StickyContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 200px;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: white;
`;

const CollapseArrow = styled.i`
  align-self: flex-end;
  margin: 25px 0px;
  font-size: 20px;

  ${
    media.phone`
      font-size: 30px;
    `
  }
`;

const HeaderContainer = styled.div`
  height: 10%;
  max-height: 300px;
  width: 100%;
`;

const FooterContainer = styled.div`
  justify-self: end;
  padding: 16px 0px;
  margin-top: 20px;
  width: 100%;
`;

const NavigationList = styled.ul`
  list-style-type: none;

  a {
    text-decoration: none;
  }

  li {
    line-height: 1;
    margin: 20px 0;

    &:hover {
      text-decoration: underline;
    }
  }
`;

class UnconnectedExpandButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.expand = this.expand.bind(this);
  }

  expand() {
    this.props.dispatch(setSidebarCollapsed(false));
  }

  render() {
    return <StyledExpandButton {...this.props} className="fas fa-bars" onClick={this.expand} />;
  }
}

class NavMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.collapse = this.collapse.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.collapseForSmallerScreens = this.collapseForSmallerScreens.bind(this);
    this.state = { width: window.innerWidth };
    this.collapseForSmallerScreens(null, this.state.width);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.collapseForSmallerScreens(prevState.width, this.state.width);
  }

  collapseForSmallerScreens(prevWidth, width) {
    const boundaryWidth = 1360;
    if ((prevWidth == null || prevWidth > boundaryWidth) && width <= boundaryWidth) {
      this.collapse();
    } else if ((prevWidth == null || prevWidth <= boundaryWidth) && width > boundaryWidth) {
      this.expand();
    }
  }

  expand() {
    this.props.dispatch(setSidebarCollapsed(false));
  }

  collapse() {
    this.props.dispatch(setSidebarCollapsed(true));
  }

  render() {
    return (
      <OuterContainer className={this.props.className}
        hidden={this.props.hidden}
        collapsed={this.props.collapsed}>
        <StickyContainer>
          <CollapseArrow className="fas fa-chevron-left" onClick={this.collapse} />
          <HeaderContainer>{this.props.header}</HeaderContainer>
          <NavigationList>
            {this.props.children}
          </NavigationList>
          <FooterContainer>
            <hr />
            {this.props.footer}
          </FooterContainer>
        </StickyContainer>
      </OuterContainer>
    );
  }
}

NavMenu.propTypes = propTypes;
NavMenu.defaultProps = defaultProps;

export const ExpandButton = connect(
  ({ sidebar }) => ({ sidebarCollapsed: sidebar.collapsed, sidebarHidden: sidebar.hidden })
)(UnconnectedExpandButton);
export default connect()(NavMenu);
