import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Cover from './Cover';

const propTypes = {
  title: PropTypes.string.required,
  subtitle: PropTypes.string.isRequired,
  platforms: PropTypes.string.isRequired,
  deliverables: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  stackOnMobile: PropTypes.bool,
  coverContent: PropTypes.node,
  children: PropTypes.node
}

const defaultProps = {
  stackOnMobile: false,
  coverContent: null,
  children: null
}

const Content = styled.div`
  margin: 0 auto;
  max-width: 1750px;
`;

export default function Showcase(props) {
  return (
    <div>
      <Cover
          title={props.title}
          subtitle={props.subtitle}
          platforms={props.platforms}
          deliverables={props.deliverables}
          url={props.url}
          stackOnMobile={props.stackOnMobile}>
        {props.coverContent}
      </Cover>
      <Content>
        {props.children}
      </Content>
    </div>
  );
}
