import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from '../util/media';

const propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  platforms: PropTypes.string.isRequired,
  deliverables: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  stackOnMobile: PropTypes.bool,
  children: PropTypes.node
}

const defaultProps = {
  stackOnMobile: false,
  children: null
}

const StyledCover = styled.div`
  display: grid;
  grid-template-rows: 2fr 5fr 2fr 6fr 2fr;
  grid-template-columns: [content] 3fr [rightspace] 2fr;
  margin: 0 auto;
  height: 100vh;
  min-height: 1000px;
  max-height: 1250px;
  width: 100%;
  max-width: 1900px;
  overflow: hidden;

  ${
    media.tablet`
      min-height: ${props => props.stackOnMobile ? '1500px' : '1000px'};
      grid-template-columns: ${props => props.stackOnMobile ?
          "[content] 1fr" : "1fr [content] 2fr [rightspace] 6fr"};
    `
  }

  ${
    media.phone`
      grid-template-columns: ${props => props.stackOnMobile ?
          "[content] 1fr" : "1fr [content] 2fr [rightspace] 8fr"};
    `
  }
`;

const CoverContent = styled.div`
  grid-column: content;
  display: grid;
  grid-row: 3/5;
  justify-items: center;
  align-items: start;
  text-align: center;

  ${
    media.phone`
      grid-row: 2/5;
    `
  }
`;

const Title = styled.h1`
  font-size: 5rem;

  ${
    media.phone`
      font-size: 3rem;
    `
  }
`;

const Summarised = styled.div`
  margin: 0px 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 50px;
  justify-items: center;
  width: 80%;

  div {
    width: 100%;
  }

  ${
    media.phone`
      margin: 0px;
      grid-template-columns: 1fr;
    `
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-flow: flex-column;
  height: 1200px;
  max-height: 125%;
  overflow: hidden;
  grid-column: rightspace;
  grid-row: 2/6;
  align-self: end;
  margin-bottom: -25%;
  margin-left: auto;
  margin-right: auto;

  ${
    media.tablet`
      height: ${props => props.stackOnMobile ? 'auto' : '1200px' };
      grid-column: ${props => props.stackOnMobile ? 'content' : 'rightspace'};   
      grid-row: ${props => props.stackOnMobile ? '6' : '2/6'};   
      margin: ${props => props.stackOnMobile ? '100px 5% -25% 5%' : 'auto auto -25% auto'};
    `
  }
`;


function Cover(props) {
  return (
    <StyledCover stackOnMobile={props.stackOnMobile}>
      <CoverContent>
        <Title>{props.title}</Title>
        <h2>{props.subtitle}</h2>
        <Summarised>
          <div>
            <p><b>Platforms</b></p>
            <p>{props.platforms}</p>
          </div>
          <div>
            <p><b>Deliverables</b></p>
            <p>{props.deliverables}</p>
          </div>
          <div>
            <p><b>Interested?</b></p>
            <a href={props.url}>Visit {props.title}</a>
          </div>
        </Summarised>
      </CoverContent>
      <MainContent stackOnMobile={props.stackOnMobile}>
        {props.children}
      </MainContent>
    </StyledCover>
  );
}

Cover.propTypes = propTypes;
Cover.defaultProps = defaultProps;

export default Cover;
