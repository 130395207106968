import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import Home from './pages/Home';
import Showcase from './pages/Showcase';
import NavMenu, { ExpandButton } from './components/NavMenu';

const lightTheme = {
  primaryColor: "#23242c"
};

const theme = lightTheme;

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0px;
    padding: 0px;
    height: 100%;
    width: 100%;
  }

  body {
    font-family: Roboto;
    font-size: 1rem;
		color: ${theme.primaryColor};
  }

  h1 {
    font-family: Vollkorn;
    font-weight: 700;
    font-size: 3rem;
    margin: 0px;
  }

  h2 {
    font-size: 2rem;
    font-weight: 700;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 700;
  }


  ul,ol,dl,p,a {
    font-size: 1.5rem;
    color: black;
  }

  ul {
    padding-inline-start: 20px;
  }

  a {
    font-family: Source Sans Pro;
  }

  li, p {
    line-height: 1.5;
  }
`

const OuterContainer = styled.div`
  display: flex;
  flex: auto;
  width: 100%;
  height: 100%;
`;

const StyledNavMenu = styled(NavMenu)`
  margin-right: 50px;
  flex-basis: 200px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const Social = styled.div`
  margin-top: 20px;

  > * {
    margin: 10px;
    font-size: 30px;
  }
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
`;

function App(props) {
  return (
    <div>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <OuterContainer>
          <ExpandButton />
          <StyledNavMenu
            collapsed={props.sidebarCollapsed}
            hidden={props.sidebarHidden}
            footer={
            <Social>
              <a href="https://github.com/RobinSiep"><i className="fab fa-github"></i></a>
              <a href="https://www.hackthebox.eu/profile/75478"><i className="fas fa-cube"></i></a>
              <a href="https://www.linkedin.com/in/robin-siep"><i className="fab fa-linkedin"></i></a>
            </Social>
          }>
            <li><Link to="/">About me</Link></li>
            <li><Link to="/work">My work</Link></li>
          </StyledNavMenu>
          <Content>
            <Switch>
              <Route exact
                path="/"
                component={Home} />
              <Route exact
                path="/work"
                component={Showcase} />
            </Switch>
          </Content>
          <GlobalStyle />
        </OuterContainer>
      </BrowserRouter>
    </ThemeProvider>
  </div>
  );
}

export default connect(
  ({ sidebar }) => ({ sidebarCollapsed: sidebar.collapsed, sidebarHidden: sidebar.hidden })
)(App);
