import React from 'react';
import styled from 'styled-components';
import Headline from '../components/Headline';
import Category from '../components/Category';
import Showcase from '../components/Showcase';
import ipadScreenshot from '../images/coder.png';
import macbookScreenshot from '../images/code-r-macbook.png';
import phoneScreenshot from '../images/code-r-phone.png';
import codeImg from '../images/code.svg';
import media from '../util/media';

const IPadImage = styled.img`
  width: 100%;
  align-self: center;
`

const Highlight = styled.span`
  color: #06bbd6;
`;

const Screenshots = styled.div`
  height: auto;
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 50px;
  justify-items: center;
  align-items: center;

  ${
    media.phone`
      grid-template-columns: 1fr;
    `
  }
`;

const Screenshot = styled.img`
  width: 100%;

  ${
    media.phone`
      width: 80%;
      display: ${props => props.main ? 'block' : 'none'};
    `
  }
`;

export default function CoderShowcase() {
  return (
    <Showcase
      title="Code R"
      subtitle="A small team for customers with big ideas."
      platforms="Web"
      deliverables="Promotional website"
      url="https://coderstudio.nl"
      stackOnMobile
      coverContent={
        <IPadImage src={ipadScreenshot} />
      }>
      <Headline title="My role">
        I'm the co-founder of the development company working as a team of 2. My primary roles are full-stack developer and system architect.
      </Headline>
      <Screenshots>
        <Screenshot main src={phoneScreenshot} />
        <Screenshot src={macbookScreenshot} />
      </Screenshots>
      <Category title="Technologies" img={codeImg}>
        The website is built in <Highlight>React</Highlight>. <Highlight>Docker</Highlight> was actively used for both deployment and development.
      </Category>
    </Showcase>
  );
}
