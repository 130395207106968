import React from 'react';
import styled from 'styled-components';
import media from '../util/media';
import PropTypes from 'prop-types';

const propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};

const defaultProps = {
  children: null
};

const StyledHeadline = styled.div`
  margin: 200px auto;
  max-width: 800px;
  width: 100%;

  ${
    media.phone`
      width: 80%;
    `
  }
`;

function Headline(props) {
  return (
    <StyledHeadline>
      <h2>{props.title}</h2>
      <p>{props.children}</p>
    </StyledHeadline>
  );
}

Headline.propTypes = propTypes;
Headline.defaultProps = defaultProps;

export default Headline;
