import React from 'react';
import styled from 'styled-components';

const OuterContainer = styled.div`
`;

const PagingButton = styled.div`
  display: flex;
  position: fixed;
  top: 50%;
  bottom: 50%;
  right: ${props => props.right? '0px' : 'none'};
  font-size: 25px;
  margin: 25px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);
  align-items: center;
  justify-content: center;
`;

const ChildWrapper = styled.div`
  > :not(:nth-child(${props => props.visibleChildIndex + 1})) {
    display: none;
  }
`;

class HorizontalPager extends React.PureComponent {
  constructor(props) {
    super(props);
    this.pageLeft = this.pageLeft.bind(this);
    this.pageRight = this.pageRight.bind(this);
    this.showingFirstPage = this.showingFirstPage.bind(this);
    this.showingLastPage = this.showingLastPage.bind(this);
    this.state = {
      visibleChildIndex: 0
    };
  }

  pageLeft() {
    if (this.showingFirstPage()) {
      return;
    }

    this.setState(state => ({
      visibleChildIndex: state.visibleChildIndex - 1
    }));
  }

  pageRight() {
    if (this.showingLastPage()) {
      return;
    }

    this.setState(state => ({
      visibleChildIndex: state.visibleChildIndex + 1
    }));
  }

  showingFirstPage() {
    return this.state.visibleChildIndex == 0;
  }

  showingLastPage() {
    return this.state.visibleChildIndex == this.props.children.length - 1
  }

  render() {
    const showingFirstPage = this.showingFirstPage();
    const showingLastPage = this.showingLastPage();

    return (
      <OuterContainer>
        {!showingFirstPage ? (
          <PagingButton onClick={this.pageLeft}><i className="fas fa-chevron-left" /></PagingButton>
        ) : null}
        {!showingLastPage ? (
          <PagingButton right onClick={this.pageRight}><i className="fas fa-chevron-right" /></PagingButton>
        ) : null}
        <ChildWrapper visibleChildIndex={this.state.visibleChildIndex}>
          {this.props.children}
        </ChildWrapper>
      </OuterContainer>
    );
  }
}

export default HorizontalPager;
