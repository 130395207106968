import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Me from '../images/personal-photo.jpg';
import WaveImg from '../images/wave.png';
import { setSidebarHidden } from '../actions/sidebar';
import Experience from '../components/Experience';
import Skills from '../components/Skills';
import media from '../util/media';

const OuterContainer = styled.div`
  display: grid;
  grid-template-columns: [about] auto [photo] 500px;

  ${
    media.tablet`
      grid-template-columns: [about] auto [photo] 375px;
    `
  }

  ${
    media.phone`
      display: block;
    `
  }


`;

const Content = styled.div`
  grid-column: about;
  padding: 150px 50px 150px 50px;
  max-width: 100%;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
`;

const Headline = styled.h1`
  font-size: 5rem;
`;

const SideContent = styled.div`
  position: sticky;
  top: 0;
  right: 0;
  grid-column: photo;
  background-color: ${props => props.theme.primaryColor};
  width: 100%;
  height: 100vh;

  ${
    media.phone`
      position: static;
      height: auto;
    `
  }
`;

const Photo = styled.img`
  width: 100%;
  box-sizing: border-box;
`;

const ContactWrapper = styled.div`
  display: flex;
  flex-flow: column-reverse;
  width: 500px;

  ${
    media.tablet`
      width: 375px;
    `
  }

  ${
    media.phone`
      position: static;
      height: auto;
    `
  }
`;

const Contact = styled.div`
  width: 100%;
  padding: 50px;
  margin-bottom: 50px;
  color: white;
  background-color: ${props => props.theme.primaryColor};
  box-sizing: border-box;
`;

const Social = styled.div`
  margin-top: 20px;

  > * {
    margin: 0 20px 20px 0;
    font-size: 30px;
    color: white;
  }
`;

const Wave = styled.img`
  width: 75px;
`;

class Home extends React.PureComponent{
  componentDidMount() {
    this.props.dispatch(setSidebarHidden(false));
  }

  render() {
    return (
      <OuterContainer>
        <Content>
          <Headline>Hi, I'm Robin <Wave src={WaveImg} /></Headline>
          <h2>Full-stack developer, software architect and tech entrepreneur</h2>
          <p>I specialise in web and mobile application development with a focus on quality and security. At night I like to practice pen-testing on CTF challenges.</p>
          <p>After working at Bynder, I've co-founded two companies. I'm currently active as full-stack developer and co-founder at <a href='http://coderstudio.nl'>Code R</a>, we are always looking for work so don't hesitate to contact me!</p>
          <Skills />
          <h1>Experience</h1>
          <Experience role="Full-stack Developer & Co-founder" company="Code R" time="Aug 2017 - Present" />
          <Experience role="Full-stack Developer & Co-founder" company="Invyte" time="Feb 2017 - Dec 2019" />
          <Experience role="Graduate Intern researching scalable microservice infrastructure" company="ICT Group" time="Feb 2017 - Jun 2017" />
          <Experience role="Back-end Developer" company="Bynder" time="Sep 2015 - Nov 2016" />
          <Experience role="Software Engineering Student" company="Rotterdam University of Applied Sciences" time="2013 - 2017" />
        </Content>
        <SideContent>
          <Photo src={Me} />
          <ContactWrapper>
            <Contact>
              <h1>Let's talk</h1>
              <h2>hello@robinsiep.dev</h2>
              <Social>
                <a href="https://github.com/RobinSiep"><i className="fab fa-github"></i></a>
                <a href="https://www.hackthebox.eu/profile/75478"><i className="fas fa-cube"></i></a>
                <a href="https://www.linkedin.com/in/robin-siep-6b464985"><i className="fab fa-linkedin"></i></a>
              </Social>
            </Contact>
          </ContactWrapper>
        </SideContent>
      </OuterContainer>
    );
  }
}

export default connect()(Home);
