import React from 'react';
import styled from 'styled-components';
import Python from '../images/python.png';
import Kotlin from '../images/kotlin.png';
import Swift from '../images/swift.png';
import ReactImg from '../images/react.png';
import Java from '../images/java.png';
import Solidity from '../images/solidity.png';
import PostgreSQL from '../images/postgresql.png';
import Kubernetes from '../images/kubernetes.png';
import Docker from '../images/docker.png';
import GCP from '../images/gcp.png';
import AWS from '../images/aws.png';
import HashicorpStack from '../images/hashicorp.png';

const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 75px 0;
  justify-content: center;
`;

const Skill = styled.img`
  height: 30px;
  flex-basis: 50px;
  flex-shrink: 0;
  margin: 25px 50px;
`;

export default function Skills(props) {
  return (
    <SkillsContainer>
      <Skill src={Python} />
      <Skill src={Kotlin} />
      <Skill src={Swift} />
      <Skill src={ReactImg} />
      <Skill src={Java} />
      <Skill src={Solidity} />
      <Skill src={PostgreSQL} />
      <Skill src={Kubernetes} />
      <Skill src={Docker} />
      <Skill src={GCP} />
      <Skill src={AWS} />
      <Skill src={HashicorpStack} />
    </SkillsContainer>
  );
}
